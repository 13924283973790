import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { SkillsWrapper, Details } from "../SiteInfo/styles";

import { Title, Container, Thumbnail } from "../../../styles";

const TextStatic = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <SkillsWrapper as={Container} direction={isRTL ? "rtl" : ""}>
      <Thumbnail maxWidth="100%">
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("common:Terms of using")} Downloader.tube
        </Title>
        <p>{t("terms:Terms[1]")}</p>
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("common:Privacy Policy")}
        </Title>
        <p>{t("terms:Privacy[1]")}</p>
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("terms:Cookies usage")}
        </Title>

        <p>{t("terms:Cookies[text]")}</p>
      </Thumbnail>
      <Details>
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("terms:DMCA/Copyright policy")}
        </Title>
        <p>
          {t("terms:Copyrightpolicy[text]")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contact@downloader.tube"
          >
            contact@downloader.tube
          </a>{" "}
          {t("terms:Copyrightpolicy[text2]")}
        </p>
      </Details>
    </SkillsWrapper>
  );
};

export default TextStatic;
