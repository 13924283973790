import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { ThemeContext } from "../../../providers/ThemeProvider";

import { IntroWrapper, Details } from "../Intro/styles";
import { Title, Container, Wrapper } from "../../../styles";

const IntroStatic = ({ titleContent, title }) => {
  const { theme } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <Wrapper as="main">
      <IntroWrapper as={Container}>
        <Details theme={theme} direction={isRTL ? "rtl" : ""}>
          <Title marginBottom="1.3rem">{title}</Title>
          <h1>{titleContent}</h1>
        </Details>
        {/* <Thumbnail>
          <img src={dev} alt="Download videos" />
        </Thumbnail> */}
      </IntroWrapper>
    </Wrapper>
  );
};

export default IntroStatic;
