import React from "react";
// import AnchorLink from "react-anchor-link-smooth-scroll";
// import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// import { ThemeContext } from "../../../providers/ThemeProvider";
import { SkillsWrapper, Details } from "../SiteInfo/styles";
// import QuideImg from "../../common/Icons/Quide";
import { Title, Container, Thumbnail } from "../../../styles";

export const Text = () => {
  // const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <SkillsWrapper as={Container} direction={isRTL ? "rtl" : ""}>
      <Thumbnail maxWidth="100%">
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("home:Download Video")}
        </Title>
        <p>{t("home:Video Downloader[text]")}</p>
        <p>{t("home:Video Downloader[text]2")}</p>
        <p>{t("home:More than 500 Websites Supported")}</p>
        <p>{t("home:Online Video Downloader[text]")}</p>
      </Thumbnail>
      <Details>
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("home:Online Video Downloader")}
        </Title>

        <p>{t("home:Facebook Video Downloader")}</p>
        <p>{t("home:Facebook Video Downloader[text]")}</p>
        <p>{t("home:Dailymotion Video Downloader")}</p>
        <p>{t("home:Dailymotion Video Downloader[text]")}</p>
      </Details>
    </SkillsWrapper>
  );
};
