import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import {
  Container,
  // ,  Thumbnail
} from "../../../styles";
import { Wrapper, Details } from "./styles";
import ContactEmail from "./ContactEmail";

export const Contact = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <Wrapper as={Container} id="contact">
      {/* <Details direction={["ar", "fa"].indexOf(intl.locale) > -1 ? "rtl" : ""}> */}
      <Details direction={isRTL ? "rtl" : ""}>
        <ContactEmail />
      </Details>
      {/* <Thumbnail>
        <img src={contact} width="300" height="300" alt="contact in lebanon for bitcoin" />
      </Thumbnail> */}
    </Wrapper>
  );
};
