import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/common/Layout/Layout";
import { SEO } from "../components/common";
import IntroStatic from "../components/landing/StaticPages/Intro";
import TextStatic from "../components/landing/StaticPages/Text";
import { Contact } from "../components/landing";

const IndexPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <>
      <SEO
        lang={language}
        title={t("common:Terms of Service")}
        description={t("common:Terms of Service")}
        keywords={["Downloader.Tube Terms", t("common:Terms of Service")]}
      />
      <IntroStatic
        title="Downloader.Tube"
        titleContent={t("common:Terms of Service")}
      />
      <TextStatic />
      <Contact />
    </>
  );
};

const customProps = {
  localeKey: "terms", // same as file name in src/i18n/translations/your-lang/index.js
};

export default Layout(customProps)(IndexPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "home", "terms"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
