import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { ThemeContext } from "../../../providers/ThemeProvider";
import { Grid, StepCard } from "./styles";
import { Title, Container, Wrapper } from "../../../styles";

// import copyLink from "../../../assets/illustrations/copyLink.svg";
// import pasteLink from "../../../assets/illustrations/paste.svg";
// import downloadImage from "../../../assets/illustrations/down.svg";

import { DynamicImg } from "../../common";

// import Img from "gatsby-image"

export const Steps = () => {
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <Wrapper as={Container} id="steps">
      <Title
        as="h2"
        fontSize="29pt"
        fontMobile="24pt"
        direction={isRTL ? "rtl" : ""}
      >
        {t("faq:How to Download Video Online")}
      </Title>
      <Grid>
        <StepCard theme={theme} widthAndHeight="150px">
          <div className="overlay">
            <div className="title">
              <span className="text">Step 1</span>
              <span className="number">1</span>
            </div>
            <div className="divImg">
              <DynamicImg
                filename="copyLink"
                alt={t("faq:How to Download Video Online")}
              />
            </div>
            {/* <img
              src={copyLink}
              alt="how to download video"
              width="150px"
              height="150px"
            /> */}
            <p>{t("faq:HowToSave[text]")}</p>
          </div>
        </StepCard>
        <StepCard theme={theme}>
          <div className="overlay">
            <div className="title">
              <span className="text">Step 2</span>
              <span className="number">2</span>
            </div>
            <div className="divImg">
              <DynamicImg
                filename="paste"
                alt={t("faq:How to Download Video Online")}
              />
            </div>
            {/* <img
              src={pasteLink}
              alt="how to download video"
              width="150px"
              height="150px"
            /> */}
            <p>{t("faq:HowToSave[text]3")}</p>
          </div>
        </StepCard>
        <StepCard theme={theme}>
          <div className="overlay">
            <div className="title">
              <span className="text">Step 3</span>
              <span className="number">3</span>
            </div>
            <div className="divImg">
              <DynamicImg
                filename="step3"
                alt={t("faq:How to Download Video Online")}
              />
            </div>
            {/* <img
              src={downloadImage}
              alt="how to download video"
              width="150px"
              height="150px"
            /> */}
            <p>{t("faq:HowToSave[text]4")}</p>
          </div>
        </StepCard>
      </Grid>
    </Wrapper>
  );
};
