import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import { Link } from "gatsby";

// import { ThemeContext } from "../../../providers/ThemeProvider";
import { SkillsWrapper, Details } from "./styles";

// import QuideImg from "../../common/Icons/Quide";
import { Title, Container, Thumbnail } from "../../../styles";

// import dev from "../../../assets/illustrations/download.svg";
import { DynamicImg } from "../../common";

export const SiteInfo = ({ isStatic, pageContext }) => {
  // const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const isSoundCloud = pageContext
    ? pageContext.node.name !== "Soundcloud"
    : null;
  return (
    <div id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <DynamicImg filename="info" alt={t("home:Video Downloader")} />
          {/* <img src={dev} alt="download video online" /> */}
        </Thumbnail>

        <Details direction={isRTL ? "rtl" : ""}>
          <Title as="h2" fontSize="29pt" fontMobile="24pt">
            {!isStatic ? (
              <span>{t("home:Video Downloader")}</span>
            ) : (
              <>
                {t("template:Download")} {pageContext.node.name}{" "}
                {isSoundCloud ? (
                  <span>{t("template:Video")}</span>
                ) : (
                  <span>{t("template:Music")}</span>
                )}
              </>
            )}
          </Title>
          {!isStatic ? (
            <>
              <p>{t("home:AboutText1")}</p>
              <p>{t("home:AboutText2")}</p>
              <p>{t("home:AboutText3")}</p>
            </>
          ) : isSoundCloud ? (
            <>
              <Title as="h3" fontSize="21pt" fontMobile="16pt">
                {t("template:How To Download")} {pageContext.node.name}{" "}
                {t("template:Video")}?
              </Title>
              <p>
                &#x2780; {t("template:Open")} {pageContext.node.name},{" "}
                {t("template:go to the video you want to download it and")}{" "}
                {t("template:Copy video Link")}.
              </p>
              <p>
                &#10113; {t("template:Paste")}{" "}
                {t("template:the video Link in the input above")}.
              </p>
              <p>
                &#10114; {t("template:Click")} {t("template:Download")}{" "}
                {t("template:to download")} {pageContext.node.name}{" "}
                {t("template:Video")}
              </p>
              <Title as="h3" fontSize="21pt" fontMobile="16pt">
                {t("home:Video Downloader")}
              </Title>
              <p>{t("home:AboutText2")}</p>
              <p>{t("home:AboutText3")}</p>
            </>
          ) : (
            <>
              <Title as="h3" fontSize="21pt" fontMobile="16pt">
                {t("template:How To Download")} {pageContext.node.name}{" "}
                {t("template:Music")}?
              </Title>
              <p>
                &#x2780; {t("template:Open")} {pageContext.node.name},{" "}
                {t("template:go to the music you want to download it and")}{" "}
                {t("template:Copy audio Link")}.
              </p>
              <p>
                &#10113; {t("template:Paste")}{" "}
                {t("template:the video Link in the input above")}.
              </p>
              <p>
                &#10114; {t("template:Click")} {t("template:Download")}{" "}
                {t("template:to download")} {pageContext.node.name}{" "}
                {t("template:Music")}
              </p>
              <Title as="h3" fontSize="21pt" fontMobile="16pt">
                {t("home:Video Downloader")}
              </Title>
              <p>{t("home:AboutText2")}</p>
              <p>{t("home:AboutText3")}</p>
            </>
          )}
        </Details>
      </SkillsWrapper>
    </div>
  );
};
