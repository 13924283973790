import styled from "styled-components";
// import detailsIllustration from 'assets/illustrations/details.svg';

// background-image: url(${detailsIllustration});
// export const Wrapper = styled.div`
//   background-size: contain;
//   background-position: left top;
//   background-repeat: no-repeat;
// `;

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2,
  h3,
  p {
    direction: ${({ direction }) => (direction ? direction : "")};
  }
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;
  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }
  direction: ${({ direction }) => (direction ? direction : "")};
  p {
    font-size: 17pt;
  }
`;

// export const Thumbnail = styled.div`
//   flex: 1;
//   @media (max-width: 960px) {
//     width: 100%;
//     margin-bottom: 2rem;
//   }
//   img {
//     max-width: 300px;
//   }
// `;
