import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FaDownload } from "react-icons/fa";

import { ThemeContext } from "../../../providers/ThemeProvider";
// import dev from "assets/illustrations/introo.svg";
import {
  IntroWrapper,
  Details,
  // Thumbnail,
  Input,
} from "./styles";
import {
  Button,
  Title,
  Container,
  Wrapper,
  LoadingStyle,
} from "../../../styles";
import RedirectLink from "../../common/RedirectLink";

import { isSSR } from "../../../api/utils";

// import { Message } from "../../common";
const Message = React.lazy(() => import("../../common/Message"));

export const Intro = ({
  submitForm,
  inputValue,
  updateInput,
  loading,
  disabelBtn,
  retried,
  errorMessage,
  titleContent,
}) => {
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <Wrapper as="main">
      <IntroWrapper as={Container}>
        <Details theme={theme} direction={isRTL ? "rtl" : ""}>
          <Title marginBottom="1.3rem">Downloader.Tube</Title>
          <h1>{titleContent}</h1>
          <form onSubmit={submitForm}>
            <Input
              type="text"
              placeholder={t("home:Paste Link Here")}
              aria-label="Large"
              value={inputValue}
              onChange={updateInput}
              disabled={loading || disabelBtn ? true : ""}
            />
            <p className="pLikeSpan">
              {t("home:By using our service you accept our")}{" "}
              <RedirectLink to="/terms/">
                {t("common:Terms of Service")}
              </RedirectLink>{" "}
              {t("home:and")}{" "}
              <RedirectLink to="/terms/">
                {t("common:Privacy Policy")}
              </RedirectLink>
            </p>
            <Button
              theme={theme}
              type="submit"
              disabled={loading || disabelBtn ? true : ""}
            >
              {loading ? <LoadingStyle /> : <FaDownload fill="#d43e2f" />}{" "}
              {t("common:Download")}
            </Button>
          </form>

          {retried && !isSSR && (
            <React.Suspense fallback={<div />}>
              <Message
                classType="warning"
                message={t(
                  "home:There was an error, the request is being retried"
                )}
                theme={theme}
              />
            </React.Suspense>
          )}
          {errorMessage && !isSSR && (
            <React.Suspense fallback={<div />}>
              <Message
                classType="danger"
                message={t(
                  "home:Sorry an error has occured while processing your request."
                )}
                theme={theme}
              />
            </React.Suspense>
          )}
        </Details>
        {/* <Thumbnail>
          <img src={dev} alt="Download videos" />
        </Thumbnail> */}
      </IntroWrapper>
    </Wrapper>
  );
};
