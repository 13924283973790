import React from "react";
// import { FaRegNewspaper, FaRegFileAlt, FaRegHandshake } from "react-icons/fa";
import { useTranslation } from "gatsby-plugin-react-i18next";

// import { ThemeContext } from "../../../../providers/ThemeProvider";
import { Title } from "../../../../styles";

export default () => {
  // const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <>
      <Title as="h4" fontSize="29pt" fontMobile="24pt">
        {t("home:Contact Us")}
      </Title>
      <p>
        {t("home:contactPage")}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:contact@downloader.tube"
        >
          contact@downloader.tube
        </a>
      </p>
    </>
  );
};
