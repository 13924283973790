import styled from "styled-components";

export const Grid = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  place-items: center;
  justify-content: center;
`;
export const StepCard = styled.div`
  margin: 20px auto;
  width: 260px;
  min-width: 260px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.16);
  height: 400px;
  position: relative;
  display: flex;
  place-items: center;
  border-radius: 10px;
  background: ${({ theme }) => (theme === "light" ? "#FFF" : "#242526")};
  .divImg {
    max-width: ${({ widthAndHeight }) =>
      widthAndHeight ? widthAndHeight : "200px"};
    max-height: ${({ widthAndHeight }) =>
      widthAndHeight ? widthAndHeight : "200px"};
    margin-bottom: 15px;
  }
  .overlay {
    display: flex;
    flex-direction: column;
    padding: 1rem 20px 1.5rem 20px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    .title {
      padding: 15px 0;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      .text {
        font-size: 19px;
        font-weight: bold;
        text-align: left;
        color: #e94535;
      }
      .number {
        color: ${({ theme }) =>
          theme === "light"
            ? "rgba(233, 69, 53,0.2)"
            : "rgba(233, 69, 53, 0.6)"};
        font-size: 100px;
        font-weight: bold;
        line-height: 70px;
        transform: translatey(-10px);
      }
    }
    p {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      line-height: 22px;
      color: ${({ theme }) => (theme === "dark" ? "#c7c7c7" : "#707070")};
      margin: 0;
    }
  }
`;
