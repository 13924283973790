import styled from "styled-components";

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  direction: ${({ direction }) => (direction ? direction : "")};
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  .pLikeSpan {
    font-size: 12pt;
    font-weight: normal;
    line-height: normal;
    color: ${({ theme }) => (theme === "light" ? "#707070" : "#e6e6e6")};
    margin-bottom: 0.9rem;
  }
  h1 {
    margin-bottom: 1rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === "light" ? "#707070" : "#e6e6e6")};
    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }
`;

// export const Thumbnail = styled.div`
//   flex: 1;
//   @media (max-width: 960px) {
//     width: 100%;
//   }
//   img {
//     width: 100%;
//   }
// `;

export const Input = styled.input`
  margin-bottom: 0.5rem;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled,
  &[disabled] {
    background-color: whitesmoke;
    border-color: whitesmoke;
    color: #7a7a7a;
    cursor: not-allowed;
  }
`;
